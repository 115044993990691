import React, { useMemo } from 'react';
import { Category } from 'shared/types/product/Category';
import { useFormat } from 'helpers/hooks/useFormat';

interface Props {
  categoryId?: string;
  categories: Category[];
}

const Breadcrumbs: React.FC<Props> = ({ categoryId, categories }) => {
  const currentCategory = useMemo(() => {
    return (categories.find((category) => category.categoryId === categoryId) ?? {}) as Category;
  }, [categories, categoryId]);
  const { formatMessage: formatMessagePadi } = useFormat({ name: 'padi' });
  const allCoursesLabel = formatMessagePadi({
    id: 'allCourses',
    defaultMessage: 'All Courses',
  });
  const categoryName = currentCategory.slug == 'courses' ? allCoursesLabel : currentCategory.name;
  if (!categoryId) return <></>;

  return (
    <div className="flex flex-col items-center">
      <h1 className="mt-20 text-22 leading-[35px] md:text-26 lg:text-28">{categoryName}</h1>
    </div>
  );
};

export default Breadcrumbs;
