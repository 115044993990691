import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Product } from 'shared/types/product/Product';
import Button from 'components/commercetools-ui/atoms/button';
import ProductTile from 'components/commercetools-ui/organisms/product/product-tile';
import PromotionalTile from 'components/padi-ui/promotional-tile';
import { PromotionalTileProps } from 'components/padi-ui/promotional-tile/types';
import { PLP_PRODUCT_CLICKED } from 'helpers/constants/events';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { ProjectConfig } from 'types/project-config';
import { useProductList } from '../../context';

interface Props {
  products: Product[];
  projectConfig?: ProjectConfig;
  promotionalTileProps?: PromotionalTileProps;
}

const List: React.FC<Props> = ({ products, projectConfig, promotionalTileProps }) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { searchQuery, loadMore, totalItems } = useProductList();
  const loadedAll = useMemo(() => products.length === totalItems, [products, totalItems]);

  const [isTabletSizeOrLarger] = useMediaQuery(tablet);

  const [isLoading, setIsLoading] = useState(false);

  const handleLoadMore = async () => {
    setIsLoading(true);
    loadMore();
  };

  useEffect(() => {
    setIsLoading(false);
  }, [products]);

  // Remove lazy loading on first n of images.
  const handleImagePriority = useCallback(
    (index: number) => {
      if (isTabletSizeOrLarger) {
        return index < 6;
      }
      return index < 3;
    },
    [isTabletSizeOrLarger],
  );

  return (
    <div>
      <div className="grid grid-cols-1 gap-16 pt-32 sm:grid-cols-2 md:grid-cols-3">
        {products.map((product, index) => (
          <Fragment key={product.productId}>
            <ProductTile
              imagePriority={handleImagePriority(index)}
              product={product}
              titleHtag="h3"
              isSearchResult={!!searchQuery}
              onClick={() => {
                gtag('event', PLP_PRODUCT_CLICKED, product);
              }}
              projectConfig={projectConfig}
            />
            {index === 2 && promotionalTileProps && (
              <div className="min-h-350">
                <PromotionalTile {...promotionalTileProps} />
              </div>
            )}
          </Fragment>
        ))}
      </div>
      <Button
        className="mx-auto mt-90 block rounded-md px-48 py-12"
        disabled={loadedAll}
        onClick={handleLoadMore}
        loading={isLoading}
        variant="primary"
      >
        {formatProductMessage({ id: 'load.more', defaultMessage: 'Load More' })}
      </Button>
    </div>
  );
};

export default List;
