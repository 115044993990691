import React from 'react';
import { useParams } from 'next/navigation';
import { Category } from 'shared/types/product/Category';
import { useFormat } from 'helpers/hooks/useFormat';

interface CategoryMenuProps {
  categories: Category[];
  activeCategory: Category;
}

const CategoryMenu: React.FC<CategoryMenuProps> = ({ categories, activeCategory }) => {
  const { locale } = useParams();
  const { formatMessage: formatMessagePadi } = useFormat({ name: 'padi' });
  const { formatMessage: formatMessageProduct } = useFormat({ name: 'product' });
  const courseCategorySlugs = ['courses', 'experience-level', 'course-type', 'activity-type'];

  const activeClass = 'font-bold text-padi-gray-darker';
  const nonActiveClass = 'hover:text-padi-gray-darker';
  const padiBlueLink = 'text-padi-blue hover:text-padi-blue-darker';

  return (
    <div>
      <h3 className="sr-only">
        {formatMessageProduct({
          id: 'categories',
          defaultMessage: 'Categories',
        })}
      </h3>
      {categories.map(
        (category, index) =>
          category?.slug &&
          courseCategorySlugs.includes(category?.slug) && (
            <div key={`${category.name}-${index}`} className="pb-20">
              <h3 className="mb-10 text-base font-bold">
                <a
                  href={`/${locale}${category._url}`}
                  className={activeCategory?.slug === category.slug ? activeClass : nonActiveClass}
                >
                  {category.slug === 'courses'
                    ? formatMessagePadi({
                        id: 'allCourses',
                        defaultMessage: 'All Courses',
                      })
                    : category.name}
                </a>
              </h3>
              <ul role="list" className="space-y-8 text-sm text-gray-900">
                {category?.subCategories &&
                  category?.subCategories.map((subCategory, index) => (
                    <li key={`${subCategory.name}-${index}`} className="pb-5">
                      <a
                        href={`/${locale}${subCategory._url}`}
                        className={activeCategory?.slug === subCategory.slug ? activeClass : nonActiveClass}
                      >
                        {subCategory.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          ),
      )}
      <div className="pb-20">
        <h3 className="mb-10 text-base font-bold">
          {formatMessagePadi({
            id: 'padiClub',
            defaultMessage: 'PADI Club',
          })}
        </h3>
        <ul role="list" className="space-y-4 text-sm text-gray-900">
          <li className="pb-5">
            <a
              href={`/${locale}/product-definition/promotion-product-definition/promotion-club-discount/`}
              className={activeCategory?._url?.includes('promotion-club-discount') ? activeClass : nonActiveClass}
            >
              {formatMessagePadi({
                id: 'clubDiscountEligible',
                defaultMessage: 'Club Discount Eligible',
              })}
            </a>
          </li>
          <li className="pb-5">
            <a href={`/${locale}/padi-club/`} className={padiBlueLink}>
              {formatMessagePadi({
                id: 'learnAboutPADIClub',
                defaultMessage: 'Learn about PADI Club',
              })}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CategoryMenu;
